import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import StillHaveQuestions from '../components/StillHaveQuestions'
import { HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import FormWrapper from '../components/form/FormWrapper'
import InputField from '../components/form/InputField'
import isEmail from '../components/form/validators/isEmail'
import useFormState from '../components/form/hooks/useFormState'

const GoodRating = () => {
  return (
    <div>
      Select one of the options below to share your experience.
      <br />
      <a
        href="https://search.google.com/local/writereview?placeid=ChIJM4VAK5my2YgREKPNEWAtcI0"
        className="text-blue-500"
      >
        GOOGLE
      </a>
      <br />
      <a href="https://facebook.com/grayandsonsjewelers/reviews" className="text-blue-500">
        FACEBOOK
      </a>
      <br />
      <a href="https://www.yelp.com/biz/wtz69-SbjeNnzPt6V6XK2w" className="text-blue-500">
        YELP
      </a>
    </div>
  )
}

const PoorRating = () => {
  const [form, , updateForm] = useFormState({ name: '', email: '' })

  return (
    // contact form
    <div>
      <FormWrapper form={form} name={'Rate Your Experience'} className="md:pr-2">
        <InputField
          name={'name'}
          onChange={updateForm}
          form={form}
          label="Full Name"
          showErrorMessage={false}
          required={true}
          // className="--home-subscribe-animations"
        />
        <InputField
          type={'email'}
          name={'email'}
          onChange={updateForm}
          form={form}
          label="E-Mail"
          validator={isEmail}
          required={true}
          showErrorMessage={false}
          // className="--home-subscribe-animations delay1"
        />
        <InputField
          type={'textarea'}
          rows="5"
          name={'description'}
          label={'Description'}
          form={form}
          onChange={updateForm}
          required={true}
        />
        <button className="red-raised-btn --home-subscribe-animations delay2 my-2 px-5 py-1">
          Subscribe
        </button>
      </FormWrapper>
    </div>
  )
}

const RateYourExperience = () => {
  const [goodRating, setGoodRating] = React.useState(null)
  return (
    <Layout className={'container mx-auto'} breadcrumbs={false} canonical="/grayandsons-fanclub/">
      <Seo
        description="With over 45 years of outstanding, top-rated customer service, it is no surprise so many people love shopping at Gray and Sons. "
        title="Gray and Sons Jewelers | Rate Our Service "
      />
      <h1 className="underline-title my-5 text-center text-[calc(1.2rem+1vw)] uppercase">
        Rate Your Experience at <nobr>Gray & Sons </nobr>
      </h1>
      <h2 className="uppercase my-5 block w-full text-4xl font-black text-center">
        How did we do?
      </h2>
      <p>
        Your feedback is important to us. Please take a moment to rate your experience with Gray &
        Sons.
        <br />
        We would love to hear your feedback.
        <br />
      </p>
      {goodRating === null && (
        <div className="min-h-full flex flex-col lg:flex-row items-center justify-center gap-4">
          <button
            onClick={() => setGoodRating(false)}
            className="bg-red-600 w-11/12 max-w-[350px] h-20 font-black text-white text-2xl lg:text-4xl flex items-center justify-center gap-3"
          >
            <HandThumbDownIcon className="w-12 h-12" /> BAD SERVICE
          </button>
          <button
            onClick={() => setGoodRating(true)}
            className="bg-green-600 w-11/12 max-w-[350px] h-20 font-black text-white text-2xl lg:text-4xl flex items-center justify-center gap-3"
          >
            <HandThumbUpIcon className="w-12 h-12" /> GREAT SERVICE
          </button>
        </div>
      )}
      {goodRating === true && <GoodRating />}
      {goodRating === false && <PoorRating />}
      <StillHaveQuestions />
    </Layout>
  )
}

export default RateYourExperience
